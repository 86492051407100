import React from "react";
import styled, { keyframes, css } from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
  font: normal normal 500 13px/16px "Montserrat", sans-serif;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  letter-spacing: -0.08em;
`;

const Svg = styled.svg`
  ${(props) => css`
    opacity: 0.1;
    animation: ${fadeInOut()} 4s cubic-bezier(0.6, 0.01, 0.06, 1) infinite;
    animation-delay: 0.5s;
  `}
`;

const Text = styled.div`
  ${(props) => css`
    opacity: 0.1;
    animation: ${fadeInOut()} 4s cubic-bezier(0.6, 0.01, 0.06, 1) infinite;
    margin-left: 12px;
    animation-delay: 0.7s;
  `}
`;

const fadeInOut = () => {
  return keyframes`
  0% {
    opacity: 0.1
  }
  14% {
    opacity: 0.3
  }
  40% {
    opacity: 0.1
  }
`;
};

const ScrollMore = ({ text }) => (
  <Container>
    <Svg
      width="8"
      height="40"
      viewBox="0 0 8 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.64645 50.3536C3.84171 50.5488 4.15829 50.5488 4.35355 50.3536L7.53553 47.1716C7.7308 46.9763 7.7308 46.6597 7.53553 46.4645C7.34027 46.2692 7.02369 46.2692 6.82843 46.4645L4 49.2929L1.17157 46.4645C0.976311 46.2692 0.659728 46.2692 0.464466 46.4645C0.269204 46.6597 0.269204 46.9763 0.464466 47.1716L3.64645 50.3536ZM3.5 0L3.5 50H4.5L4.5 0L3.5 0Z"
        fill="#555050"
      />
    </Svg>
    <Text> {text} </Text>
  </Container>
);

ScrollMore.defaultProps = {
  text: "Scroll more",
};

ScrollMore.propTypes = {
  text: PropTypes.string,
};

export default ScrollMore;
