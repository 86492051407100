import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { size } from "../theme/theme";

const Container = styled.div`
  display: flex;
  align-items: center;
  font: normal normal 300 10px/12px "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: -0.05em;
  margin-bottom: 18px;
  @media ${size.xs} {
    font-size: 14px;
    line-height: 17px;
  }
`;

const HeadlineSeparator = styled.div`
  height: 1px;
  width: 14px;
  margin: 0 8px;
  background-color: #555050;
  @media ${size.s} {
    margin: 0 16px;
    width: 24px;
  }
`;

const Headline = ({ leftElem, rightElem }) => (
  <Container>
    {leftElem} <HeadlineSeparator /> {rightElem}
  </Container>
);

Headline.defaultProps = {
  leftElem: <div> left text </div>,
  rightElem: <div> right text </div>,
};

Headline.propTypes = {
  leftElem: PropTypes.any,
  rightElem: PropTypes.any,
};

export default Headline;
