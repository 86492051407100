import React from "react";
import styled, { css } from "styled-components";
import { size } from "../theme/theme";

const Container = styled.p`
  font: normal normal 400 19px/35px "Playfair Display", serif;
  max-width: 566px;
  ${(props) =>
    props.smallVersion &&
    css`
      font-size: 17px;
      line-height: 30px;
      max-width: 463px;
    `}

  @media ${size.xs} {
    font-size: 22px;
    line-height: 45px;
    ${(props) =>
      props.smallVersion &&
      css`
        font-size: 17px;
        line-height: 30px;
      `}
  }

  @media ${size.m} {
    font-size: 27px;
    line-height: 45px;
    ${(props) =>
      props.smallVersion &&
      css`
        font-size: 20px;
        line-height: 33px;
      `}
  }
`;

const Paragraph = ({ children, smallVersion }) => (
  <Container smallVersion={smallVersion}>{children}</Container>
);

export default Paragraph;
