import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import {
  Header,
  PhoneMenu,
  ContactForm,
  RotatingButton,
  SocialLinks,
} from "./";
import { myContext } from "../provider";
import SectionNav from "./section-nav";
import { size } from "../theme/theme";

const Container = styled.main`
  transition: all 0.6s cubic-bezier(0.6, 0.01, 0.06, 1);
  background-color: white;
  transform-origin: 0% 0%;
  height: 100%;
  overflow-x: hidden;
  ${(props) => css`
    ${props.isMenuOpened &&
    `
      transform: scale(0.85) translateX(-78%);
    `}
    ${props.isContactFormOpened &&
    `
      transform: skewY(-7deg);
      margin-top: -20%;
    `}
  `}
`;

const DesktopSectionNav = styled.div`
  display: none;
  z-index: 998;
  position: fixed;
  bottom: 26px;
  right: 40px;
  height: 140px;
  width: 40%;
  @media ${size.m} {
    display: block;
  }
  @media ${size.xl} {
    right: 60px;
  }
`;

const ContactContainer = styled.div`
  z-index: 999;
  display: none;
  position: fixed;
  left: -32px;
  bottom: 80px;
  transition: bottom 0.2s ease 0.3s;
  @media ${size.m} {
    display: block;
    ${(props) =>
      props.isOpened &&
      css`
        transition: bottom 0.25s ease 0.05s;
        bottom: calc(50vh + 80px);
      `}
  }
`;

const SocialLinksContainer = styled.div`
  position: absolute;
  bottom: 120px;
  left: 48px;
`;

const ButtonContainer = styled.div`
  transform: rotate(-90deg);
  width: 140px;
  padding-bottom: 2px;
`;

const Layout = ({ children }) => {
  const context = useContext(myContext);
  return (
    <>
      <Header />
      <PhoneMenu isMenuOpened={context.isMenuOpened} />
      <Container
        isContactFormOpened={context.isContactFormOpened}
        isMenuOpened={context.isMenuOpened}
        onClick={() => context.isMenuOpened && context.set_isMenuOpened(false)}
      >
        {children}
      </Container>
      <DesktopSectionNav>
        <SectionNav onDesktop />
      </DesktopSectionNav>
      <ContactContainer isOpened={context.isContactFormOpened}>
        <SocialLinksContainer>
          <SocialLinks />
        </SocialLinksContainer>
        <ButtonContainer>
          <RotatingButton
            onClick={() => context.set_isContactFormOpened(true)}
            frontText="get in touch"
            backText="let's do it"
          />
        </ButtonContainer>
      </ContactContainer>
      <ContactForm />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
