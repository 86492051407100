import React, { useContext } from "react";
import Link from "gatsby-link";
import styled, { css } from "styled-components";
import { myContext } from "../provider";
import { SectionNav, SocialLinks } from "./";
import { size } from "../theme/theme";

const Container = styled.div`
  z-index: 99;
  transition: all 0.3s cubic-bezier(0.6, 0.01, 0.06, 1);
  position: fixed;
  top: 0;
  background-color: #2b2424;
  right: -75%;
  width: 75%;
  height: 100%;
  ${(props) => css`
    ${props.isMenuOpened &&
    css`
      right: 0%;
    `}
  `}
  @media ${size.m} {
      display: none;
  }
`;

const PhoneMenuBanner = styled.div`
  transition: all 0.2s cubic-bezier(0.6, 0.01, 0.06, 1);
  position: fixed;
  background-color: #2b2424;
  right: 0%;
  top: -15px;
  height: 15px;
  width: 100%;
  ${(props) => css`
    ${props.bottom &&
    css`
      top: 100%;
    `}
    ${props.isMenuOpened &&
    css`
      transition: all 0.7s cubic-bezier(0.6, 0.01, 0.06, 1);
      top: 0px;
      right: 70%;
      ${props.bottom &&
      css`
        top: calc(100% - 15px);
      `}
    `}
  `}
`;

const Navigation = styled.nav`
  position: relative;
  z-index: 999;
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: center;

  margin-right: 20px;

  a,
  button {
    text-transform: uppercase;
    text-align: right;
    padding: 5px 0;
    cursor: pointer;
    font: normal normal 500 24px/41px "Montserrat", sans-serif;
    letter-spacing: 0.05em;
    &:not(.active) {
      color: rgba(255, 255, 255, 0.3);
    }
  }
`;

const SocialLinksContainer = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;
  flex-direction: column;
`;

const PhoneMenu = ({ isMenuOpened }) => {
  const context = useContext(myContext);
  const showContactForm = () => {
    context.set_isMenuOpened(false);
    setTimeout(() => {
      context.set_isContactFormOpened(true);
    }, 320);
  };
  return (
    <>
      <Container isMenuOpened={isMenuOpened}>
        <PhoneMenuBanner isMenuOpened={isMenuOpened} />
        <Navigation>
          <Link
            onClick={() => context.set_isMenuOpened(false)}
            activeClassName="active"
            to="/"
          >
            About
          </Link>
          <Link
            onClick={() => context.set_isMenuOpened(false)}
            activeClassName="active"
            to="/experiences/"
          >
            Experiences
          </Link>
          <Link
            onClick={() => context.set_isMenuOpened(false)}
            activeClassName="active"
            to="/blog/"
          >
            Blog
          </Link>
          <button onClick={() => showContactForm()}>Contact</button>
        </Navigation>
        <SectionNav />
        <SocialLinksContainer>
          <SocialLinks whiteIcon />
        </SocialLinksContainer>
        <PhoneMenuBanner bottom isMenuOpened={isMenuOpened} />
      </Container>
    </>
  );
};

export default PhoneMenu;
