import React from "react";
import styled from "styled-components";

const Container = styled.button`
  position: relative;
  width: 100%;
  cursor: pointer;
  display: flex;
  font: normal normal 400 14px/17px "Montserrat", serif;
  text-transform: uppercase;
  padding: 10px 0;
  ${(props) => props.style};
  .btn {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 36px;
    transform-style: flat;
    transition: transform 0.3s ease-out;
    letter-spacing: 1px;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    .btn {
      &.front {
        transform: rotateX(-90deg) translateZ(25px);
      }
      &.back {
        transform: rotateX(0deg) translateZ(25px);
      }
    }
  }
`;

const TextFront = styled.span`
  border: 1px solid #2b2424;
  transform: rotateX(0deg) translateZ(25px);
`;

const TextBack = styled.span`
  background: #2b2424;
  color: white;
  transform: rotateX(90deg) translateZ(25px);
`;

const RotatingButton = ({ frontText, backText, onClick, style }) => {
  return (
    <Container style={style} onClick={onClick}>
      <span style={{ padding: "0 50px", visibility: "hidden" }}>Text</span>
      <TextFront className="btn front">{frontText}</TextFront>
      <TextBack className="btn back">{backText}</TextBack>
    </Container>
  );
};

export default RotatingButton;
