import React, { useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import { Container, Paragraph } from "../components";
import { size } from "../theme/theme";
import { get } from "../utils";
import { gsap, ScrollTrigger } from "gsap/all";

const bannerHeight = 230;

const Title = styled.div`
  font: normal normal 900 18px/29px "Montserrat", serif;
  letter-spacing: 0.05em;
  margin-bottom: 80px;
  max-width: 480px;
  @media ${size.s} {
    font-size: 23px;
    line-height: 30px;
  }
  @media ${size.m} {
    font-size: 25px;
    line-height: 33px;
  }
  @media ${size.l} {
    font-size: 27px;
    line-height: 40px;
    max-width: 530px;
  }
`;

const Header = styled.div`
  position: relative;
  color: #f7f7f7;
  height: ${bannerHeight}px;
  background-color: ${(props) => props.backgroundColor};
  ${(props) =>
    props.backgroundPattern &&
    css`
      background-image: url(${props.backgroundPattern});
    `}
`;

const LogoAndCompany = styled.div`
  display: flex;
  align-items: center;
  height: 130px;

  @media ${size.m} {
    height: 100%;
  }
`;

const Logo = styled.div`
  position: absolute;
  margin-left: 12px;
  z-index: 9;

  svg {
    height: auto;
    width: 42px;
  }
  @media ${size.m} {
    margin-left: 15px;
  }
  @media ${size.l} {
    margin-left: 42px;
  }
`;

const CompanyAndTechnologies = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  margin-left: 32px;
  @media ${size.m} {
    margin-left: 40px;
  }
`;

const CompanyName = styled.div`
  font: normal normal 900 24px/28px "Montserrat", serif;
  @media ${size.m} {
    font-size: 28px;
    line-height: 33px;
  }
  ${(props) =>
    props.darkText &&
    css`
      color: #2b2424;
    `}
`;

const Technologies = styled.div`
  display: flex;
  align-items: center;
  font: normal normal 600 12px/23px "Montserrat", serif;
  letter-spacing: -0.03em;
  color: white;

  ${(props) =>
    props.darkText &&
    css`
      color: #2b2424;
    `}

  &:before {
    margin-right: 12px;
    display: block;
    content: "";
    width: 38px;
    height: 1px;
    background-color: #f7f7f7;
    ${(props) =>
      props.darkText &&
      css`
        background-color: #2b2424;
      `}
  }
`;

const ParagraphContainer = styled.div`
  margin-top: 50px;
`;

const IllustrationContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  @media ${size.m} {
    position: absolute;
    max-height: ${bannerHeight}px;
    width: 50%;
    right: 10px;
    top: -14%;
    /*
    top: -20%;
    left: 65%; */
  }
  @media ${size.l} {
    width: 40%;
    right: 40px;
  }
  @media ${size.xl} {
    width: 35%;
    right: 100px;
  }
`;
const Illustration = styled(Image)`
  max-height: 340px;
  margin: -100px auto 0;

  @media ${size.s} {
  }

  @media ${size.m} {
    max-height: 430px;
    margin: -40px auto;
  }
  @media ${size.l} {
    max-height: 490px;
  }
`;

const WorkExperience = ({
  title,
  text,
  bannerColor,
  bannerPattern,
  companyName,
  technologies,
  logo,
  illustration,
  illustrationWidth,
  illustrationBottomPosition,
  isTextDark,
}) => {
  const illustrationContainerRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: illustrationContainerRef.current,
        start: "top 70%",
        end: "bottom 30%",
        scrub: 2,
      },
    });
    tl.to(illustrationContainerRef.current, {
      y: "10%",
    });
  }, []);

  return (
    <>
      <Container>
        <Title> {title}</Title>
      </Container>
      <div style={{ position: "relative" }}>
        <Header
          backgroundColor={bannerColor}
          backgroundPattern={get(bannerPattern, "asset.fixed.src")}
        >
          <LogoAndCompany>
            <Logo>
              <div dangerouslySetInnerHTML={{ __html: logo }} />
            </Logo>
            <Container>
              <CompanyAndTechnologies>
                <CompanyName darkText={isTextDark}>{companyName}</CompanyName>
                <Technologies darkText={isTextDark}>
                  {technologies.join(" · ")}
                </Technologies>
              </CompanyAndTechnologies>
            </Container>
          </LogoAndCompany>
        </Header>
        <IllustrationContainer ref={illustrationContainerRef}>
          <Illustration
            imgStyle={{ objectFit: "contain", objectPosition: "top center" }}
            width={illustrationWidth}
            bottom={illustrationBottomPosition}
            fluid={get(illustration, "asset.fluid")}
          ></Illustration>
        </IllustrationContainer>
      </div>
      <ParagraphContainer>
        <Container>
          <Paragraph smallVersion>{text}</Paragraph>
        </Container>
      </ParagraphContainer>
    </>
  );
};

WorkExperience.defaultProps = {
  title: "My title",
  text: "my text",
  companyName: "A company",
  bannerColor: "#ffffff",
  bannerPattern: {},
  logo: "",
  technologies: [],
  illustration: {},
  illustrationWidth: {
    default: null,
    small: null,
    medium: null,
    large: null,
    xlarge: null,
  },
  illustrationBottomPosition: {
    default: null,
    small: null,
    medium: null,
    large: null,
    xlarge: null,
  },
  isTextDark: false,
};

WorkExperience.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  companyName: PropTypes.string,
  bannerColor: PropTypes.string,
  bannerPattern: PropTypes.object,
  logo: PropTypes.string,
  technologies: PropTypes.array,
  illustration: PropTypes.object,
  illustrationWidth: PropTypes.object,
  illustrationBottomPosition: PropTypes.object,
  isTextDark: PropTypes.bool,
};

export default WorkExperience;
