import React, { useContext } from "react";
import styled from "styled-components";
import { size } from "../theme/theme";
import { myContext } from "../provider";

const Container = styled.div`
  margin-left: -65px;
  margin-top: 50px;
  padding: 16px;
  font: normal normal 600 12px/15px "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform-origin: bottom;
  color: #2b2424;
  transform-origin: top left;
  transition: transform 0.3s cubic-bezier(0.6, 0.01, 0.06, 1);
  ${(props) =>
    props.isHidden &&
    `
      transform: translateX(-78%);
    `}
  color: ${(props) => props.color};
  div {
    transform: rotate(-90deg);
  }
  @media ${size.xs} {
    margin-left: -70px;
    margin-top: 65px;
    font-size: 14px;
    line-height: 17px;
  }
  @media ${size.m} {
    margin-left: -50px;
    margin-top: 75px;
    font-size: 14px;
    line-height: 17px;
  }
`;

const Logo = ({ color }) => {
  const context = useContext(myContext);
  return (
    <Container color={color} isHidden={context.isMenuOpened}>
      <div>
        Thomas Morice.
        {/* <TextReveal delay={0.5}>Thomas Morice.</TextReveal> */}
      </div>
    </Container>
  );
};

export default Logo;
