import React, { useRef } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { size } from "../theme/theme";

const Container = styled.div`
  ${(props) => css`
    position: relative;
    display: flex;
    height: 100%;
  `}
`;

const LeftLine = styled.div`
  margin-right: 20px;
  height: 100%;
  opacity: 1;
  @media ${size.s} {
    margin-right: 65px;
  }
  div {
    position: absolute;
    background-color: #2b2424;
    opacity: 0.6;
    height: inherit;
    width: 1px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font: normal normal 700 17px/30px "Playfair Display", serif;
  margin-bottom: 16px;
  max-width: 550px;
  @media ${size.xs} {
    font-size: 33px;
    line-height: 50px;
  }
  @media ${size.s} {
    margin-bottom: 26px;
  }
`;

const Annotation = styled.div`
  font: italic normal 500 10px/22px "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #c6c2c2;
  @media ${size.xs} {
    font-size: 14px;
    line-height: 28px;
  }
  .bigger-annotation {
    color: rgba(43, 36, 36, 0.5);
    text-transform: none;
    font: italic normal 300 14px/25px "Montserrat", sans-serif;
    @media ${size.m} {
      font-size: 22px;
      line-height: 34px;
    }
  }
`;

const TitleAndAnnotation = ({
  title,
  annotation,
  withLeftLine,
  biggerAnnotation,
}) => {
  const container = useRef();

  return (
    <>
      <Container ref={container}>
        {withLeftLine && (
          <LeftLine>
            <div> </div>
          </LeftLine>
        )}
        <TextContainer>
          <Title>
            <h2>{title} </h2>
          </Title>
          <Annotation>
            <div className={biggerAnnotation ? "bigger-annotation" : ""}>
              {annotation}
            </div>
          </Annotation>
        </TextContainer>
      </Container>
    </>
  );
};

TitleAndAnnotation.defaultProps = {
  title: "",
  annotation: "",
  withLeftLine: false,
  biggerAnnotation: false,
};

TitleAndAnnotation.propTypes = {
  title: PropTypes.string,
  annotation: PropTypes.string,
  withLeftLine: PropTypes.bool,
  biggerAnnotation: PropTypes.bool,
};

export default TitleAndAnnotation;
