import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import Image from "gatsby-image";
import { size } from "../theme/theme";
import { gsap, ScrollTrigger } from "gsap/all";

const Container = styled.div`
  ${(props) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  `}
`;

const ImageContainer = styled.div`
  ${(props) => css`
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: -5%;
    @media ${size.xs} {
      margin: 0 20px;
      width: calc(100% - 40px);
    }
  `}
`;

const GatsbyImage = styled(Image)`
  @media ${size.s} {
    width: 420px;
  }
`;

const Text = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99;
  left: -5%;
  top: 30px;
  font: normal normal 900 13vw/15vw "Montserrat", serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: rgba(220, 220, 220, 0.4);
  @media ${size.s} {
    font-size: 72px;
    line-height: 100px;
  }
  @media ${size.m} {
    font-size: 89px;
    line-height: 100px;
  }
  @media ${size.l} {
    font-size: 123px;
    line-height: 120px;
  }
`;

const ImageWithBigText = ({ gatsbyImage, text }) => {
  const containerRef = useRef(null);
  const bigTextRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top center",
        end: "bottom top",
        scrub: 2,
      },
    });
    tl.to(bigTextRef.current, {
      y: "50%",
    });
  }, []);

  return (
    <>
      <Container>
        <ImageContainer ref={containerRef}>
          <Text ref={bigTextRef}>{text}</Text>
          <div style={{ width: "110%" }}>
            <GatsbyImage
              imgStyle={{ borderRadius: "20px" }}
              fluid={gatsbyImage}
            />
          </div>
        </ImageContainer>
      </Container>
    </>
  );
};

export default ImageWithBigText;
