import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { HamburgerMenu, Logo } from "./";
import { myContext } from "../provider";
import { size } from "../theme/theme";
import { Link } from "gatsby";

const Container = styled.header`
  ${(props) => css`
    position: relative;
    z-index: 999;
    height: ${props.theme.spaces.headerHeight};
  `}
`;

const FixedContainer = styled.div`
  ${(props) => css`
    position: fixed;
    display: flex;
    justify-content: space-between;
    top: 0;
    width: 100vw;
    transition: top 0.3s ease;
    ${props.contactFormOpened &&
    `
      top: -50%;
    `}
  `}
`;

const DesktopMenu = styled.div`
  display: none;
  position: fixed;
  right: 10%;
  top: 34px;
  a {
    position: relative;
    text-transform: uppercase;
    color: rgba(43, 36, 36, 0.35);
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.08em;
    margin: 0 18px;
    transition: color 0.3s ease;
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 1px;
      background-color: rgba(43, 36, 36, 1);
      transition: width 0.3s ease;
    }
    &:hover {
      color: rgba(43, 36, 36, 0.6);
      &:after {
        width: 8px;
      }
    }
    &.active {
      font-weight: 500;
      font-style: italic;
      color: rgba(43, 36, 36, 1);
      &:after {
        width: 20px;
      }
    }
  }
  @media ${size.m} {
    display: flex;
  }
`;

const Header = () => {
  const context = useContext(myContext);
  return (
    <Container>
      <FixedContainer contactFormOpened={context.isContactFormOpened}>
        <Logo />
        <HamburgerMenu />
        <DesktopMenu>
          <Link to="/" activeClassName="active">
            About
          </Link>
          <Link to="/experiences/" activeClassName="active">
            Experience
          </Link>
          <Link to="/blog/" activeClassName="active">
            Blog
          </Link>
        </DesktopMenu>
      </FixedContainer>
    </Container>
  );
};

export default Header;
