import React, { useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import { myContext } from "../provider";
import { size } from "../theme/theme";

const Container = styled.div`
  ${(props) => css`
    cursor: pointer;
    margin: 8px 0 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 50px;
    width: 50px;
    @media ${size.xs} {
      margin: 16px 16px 0;
    }
    @media ${size.m} {
      display: none;
    }
    div {
      height: 90%;
      margin: 0 4px;
      width: 2px;
      border-radius: 5px;
      background-color: #2b2424;
      transition: all 0.3s cubic-bezier(0.6, 0.01, 0.06, 1);

      &:nth-child(1) {
        animation: ${animateLine(90, 10)} 8s cubic-bezier(0.6, 0.01, 0.06, 1)
          infinite;
        ${props.isMenuOpened &&
        css`
          transform: rotate(45deg);
          animation: none;
          height: 70%;
          margin: 10px -1px;
          border-radius: 0;
          background-color: white;
        `}
      }
      &:nth-child(2) {
        height: 50%;
        animation: ${animateLine(50, 30)} 8s cubic-bezier(0.6, 0.01, 0.06, 1)
          infinite;
        animation-delay: 7s;
        ${props.isMenuOpened &&
        css`
          transform: rotate(-45deg);
          height: 70%;
          animation: none;
          margin: 10px 0;
          border-radius: 0;
          background-color: white;
        `}
      }
    }
  `}
`;

const animateLine = (maxHeight, decreaseBy) => {
  return keyframes`
  0% {
  height: ${maxHeight}%
  }
  3% {
  height: ${maxHeight - decreaseBy}%
  }
  5% {
    height: ${maxHeight}%
  }
  8% {
    height: ${maxHeight - decreaseBy}%
  }
  12% {
    height: ${maxHeight}%
  }
`;
};

const HamburgerMenu = () => {
  const context = useContext(myContext);
  return (
    <Container
      onClick={() => context.set_isMenuOpened(!context.isMenuOpened)}
      isMenuOpened={context.isMenuOpened}
    >
      <div></div>
      <div></div>
    </Container>
  );
};

export default HamburgerMenu;
