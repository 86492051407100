import React from "react";
import styled from "styled-components";
import Instagram from "../images/social/instagram.inline.svg";
import LinkedIn from "../images/social/linkedin.inline.svg";
import PropTypes from "prop-types";

const Container = styled.div``;

const Link = styled.a`
  display: block;
  padding: 10px;
`;

const Icon = styled.div`
  height: 19px;
  width: 19px;
  svg path {
    fill: black;
  }
  ${(props) =>
    props.whiteIcon &&
    `
    svg path {
      fill: white;
    }
  `}
`;

const SocialLinks = ({ whiteIcon }) => {
  return (
    <Container>
      <Link target="_blank" href="https://www.linkedin.com/in/thomasmorice/">
        <Icon whiteIcon={whiteIcon}>
          <LinkedIn />
        </Icon>
      </Link>
      <Link target="_blank" href="https://www.instagram.com/mr.__.t/">
        <Icon whiteIcon={whiteIcon}>
          <Instagram />
        </Icon>
      </Link>
    </Container>
  );
};

SocialLinks.defaultProps = {
  whiteIcon: false,
};

SocialLinks.propTypes = {
  whiteIcon: PropTypes.bool,
};

export default SocialLinks;
