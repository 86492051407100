import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { myContext } from "../provider";
import { size } from "../theme/theme";
import PropTypes from "prop-types";

const Container = styled.div`
  position: absolute;
  font: normal normal 100 13px/16px "Playfair display", serif;
  transform: rotate(-90deg);
  transform-origin: 0% 0%;
  height: 100%;
  width: 100%;
  right: calc(-100% + 130px);
  bottom: calc(-100% + 15px);
  @media ${size.xs} {
    font-size: 19px;
    line-height: 24px;
    right: calc(-100% + 180px);
  }
`;

const SectionLink = styled.div`
  ${(props) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 7px 0;
    color: rgba(255, 255, 255, 0.4);
    @media ${size.xs} {
      padding: 9px 0;
    }
    ${props.isOnDesktop &&
    css`
      color: rgba(43, 36, 36, 0.2);
    `}
    transition: all 0.3s cubic-bezier(0.6, 0.01, 0.06, 1);
    &:before {
      content: "";
      display: flex;
      background-color: white;
      transition: all 0.3s cubic-bezier(0.6, 0.01, 0.06, 1);
      width: 0px;
      height: 0px;
      ${props.isOnDesktop &&
      css`
        background-color: #2b2424;
      `}
    }
    ${props.active &&
    css`
      color: rgba(255, 255, 255, 1);
      ${props.isOnDesktop &&
      css`
        color: #2b2424;
      `}
      &:before {
        margin-right: 13px;
        width: 24px;
        height: 1px;
      }
    `};
  `}
`;

const SectionNav = ({ onDesktop }) => {
  const context = useContext(myContext);

  const scrollToRef = (ref) => {
    context.set_isMenuOpened(false);
    if (window.innerWidth < 770) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 400);
    } else {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <>
      <Container>
        <div>
          {context.pageSections.map((section) => {
            return (
              <SectionLink
                onClick={() => scrollToRef(section.ref)}
                active={section.key === context.currentSectionKey}
                key={section.key}
                isOnDesktop={onDesktop}
              >
                {section.title}
              </SectionLink>
            );
          })}
        </div>
      </Container>
    </>
  );
};

SectionNav.defaultProps = {
  onDesktop: false,
};

SectionNav.propTypes = {
  onDesktop: PropTypes.bool,
};

export default SectionNav;
