import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Container, Logo, TextReveal } from "../components";
import styled, { css } from "styled-components";
import { myContext } from "../provider";
import { size } from "../theme/theme";
import CloseIconSvg from "../images/close-icon.inline.svg";

const ContactContainer = styled.div`
  ${(props) => css`
    overflow: hidden;
    z-index: 9999;
    position: fixed;
    bottom: 0;
    height: auto;
    max-height: 0;
    width: 100%;
    background-color: ${props.theme.colors.darkGrey};
    /* background-color: #191717; */
    transition: max-height 0.6s ease;
    ${props.isOpened &&
    `
      max-height: 1000px;

    `}
  `}
  @media ${size.m} {
    height: 50vh;
  }
`;

const TitleAndForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  padding: 36px 0;
  @media ${size.s} {
    padding: 40px 0;
  }
  @media ${size.m} {
    padding: 52px 0;
  }
  @media ${size.m} {
    padding: 62px 0;
  }
  @media ${size.xl} {
    right: -74px;
  }
`;

const Title = styled.div`
  font: normal normal 400 19px/25px "Playfair Display", serif;
  letter-spacing: 0.1em;
  color: white;
  max-width: 210px;
  @media ${size.xs} {
    max-width: 260px;
  }
  @media ${size.s} {
    font-size: 25px;
    line-height: 35px;
    max-width: 350px;
  }

  @media ${size.m} {
    max-width: 600px;
    font: normal normal 400 39px/49px "Playfair Display", serif;
  }
`;

const CloseIcon = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 999;
  height: 41px;
  width: 40px;
  margin-right: -20px;
  transition: transform 0.3s ease;
  path {
    transition: stroke 0.4s ease 0.5s;
    stroke: #bdbdbd;
  }
  @media ${size.m} {
    position: absolute;
    right: 0px;
  }
  @media ${size.l} {
    right: -105px;
  }
  @media ${size.xl} {
    right: -74px;
  }

  ${(props) =>
    props.highlight &&
    css`
      transform: rotate(180deg);
      path {
        stroke: white;
      }
    `}
`;

const TitleAndCloseButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media ${size.m} {
    margin: 0 auto 40px auto;
  }
  @media ${size.l} {
    margin-left: -60px;
  }
`;

const FormAndSubmitButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all .3s ease .5s;
  transform: translateX(-30px);
  opacity: 0;
  ${(props) =>
    props.animateIn &&
    `
    transform: translateX(0);
    opacity: 1;
  `}
  ${(props) =>
    props.animateOut &&
    `
      transition-delay: 0s;
      transform: translateX(30px);
      opacity: 0;
  `}
  @media ${size.m} {
    float: right;
    margin-bottom: 30px;
  }
  @media ${size.l} {
    margin-right: -70px;
  }
  @media ${size.xl} {
    margin-right: -115px;
  }
`;

const Form = styled.form`
  width: calc(100% - 70px);
  @media ${size.m} {
    display: block;
    max-width: 900px;
    width: calc(100% - 110px);
  }
`;

const Input = styled.input`
  transition: all 0.3s ease;
  font: normal normal 600 11px/36px "Montserrat", serif;
  background-color: transparent;
  text-transform: uppercase;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  letter-spacing: 0.1em;
  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  &:hover,
  &:valid {
    border-bottom: 1px solid rgba(255, 255, 255, 1);
  }
  @media ${size.s} {
    font-size: 13px;
    line-height: 49px;
  }

  ${(props) =>
    props.message &&
    css`
      @media ${size.m} {
        width: 100%;
        margin-top: 16px;
      }
    `}
  ${(props) =>
    !props.message &&
    css`
      @media ${size.m} {
        width: 47%;
        &:first-child {
          margin-right: 6%;
        }
      }
    `}
`;

const SubmitContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 45px;
`;

const Submit = styled.button`
  cursor: pointer;
  position: absolute;
  transform-origin: top center;
  transform: rotate(-90deg) translate(0%, 140%);
  top: 0;
  right: -2px;
  font: normal normal 500 14px/17px "Montserrat", serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: rgba(255, 255, 255, 0.3);
  padding: 6px 25px;
  &:focus {
    background: rgba(255, 255, 255, 0.5);
  }
  &:before {
    transition: width 0.3s ease;
    z-index: -9;
    content: "";
    width: 0;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    ${(props) =>
      props.progress &&
      `
      width: ${props.progress}%;
    `}
  }

  @media ${size.l} {
    right: -35px;
  }
  @media ${size.xl} {
    right: 36px;
  }
`;

const ContactForm = () => {
  const context = useContext(myContext);
  const [name, set_name] = useState("");
  const [email, set_email] = useState("");
  const [message, set_message] = useState("");
  const [progress, set_progress] = useState(0);
  const [showThankYou, set_showThankYou] = useState();

  const netlifyForm = useRef();
  const onFormSubmit = useCallback((e) => {
    e.preventDefault();
    const formData = new FormData(netlifyForm.current);
    fetch(netlifyForm.current.getAttribute("action"), {
      method: "POST",
      headers: {
        Accept: "application/x-www-form-urlencoded;charset=UTF-8",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: new URLSearchParams(formData).toString(),
    }).then((res) => {
      if (res) {
        set_showThankYou(true);
      }
    });
  });

  useEffect(() => {
    let fieldsFilled = 0;
    if (name !== "") {
      fieldsFilled++;
    }
    if (email !== "") {
      fieldsFilled++;
    }
    if (message !== "") {
      fieldsFilled++;
    }
    set_progress((fieldsFilled * 100) / 3);
  }, [name, email, message]);
  return (
    <ContactContainer isOpened={context.isContactFormOpened}>
      <div style={{ position: "absolute", left: "-7px" }}>
        <Logo color={"white"} />
      </div>
      <TitleAndForm>
        <Container>
          <TitleAndCloseButton>
            <Title>
              <TextReveal
                animateIn={context.isContactFormOpened}
                animateOut={showThankYou}
                delay={0.2}
              >
                Don’t hesitate to drop a line
              </TextReveal>

              <TextReveal
                delay={0.3}
                animateIn={context.isContactFormOpened}
                animateOut={showThankYou}
              >
                I’ll be happy to talk
              </TextReveal>
              {showThankYou && (
                <>
                  <TextReveal animateIn={showThankYou} delay={0.3}>
                    Thanks for submitting
                  </TextReveal>
                  <TextReveal delay={0.38} animateIn={showThankYou}>
                    We’ll be in touch soon.
                  </TextReveal>
                </>
              )}
            </Title>

            <CloseIcon
              onClick={() => context.set_isContactFormOpened(false)}
              highlight={showThankYou}
            >
              {" "}
              <CloseIconSvg />
            </CloseIcon>
          </TitleAndCloseButton>
        </Container>
        <Container>
          <FormAndSubmitButton
            animateIn={context.isContactFormOpened}
            animateOut={showThankYou}
          >
            <Form
              data-netlify="true"
              method="post"
              name="contact"
              data-netlify-honeypot="bot-field"
              ref={netlifyForm}
              onSubmit={onFormSubmit}
            >
              <Input
                required
                name="name"
                onChange={(e) => {
                  set_name(e.target.value);
                }}
                value={name}
                placeholder="name"
              />
              <Input
                required
                name="email"
                onChange={(e) => {
                  set_email(e.target.value);
                }}
                value={email}
                placeholder="Email"
              />
              <Input
                required
                name="message"
                onChange={(e) => {
                  set_message(e.target.value);
                }}
                value={message}
                message
                placeholder="Message"
              />
              <input type="hidden" name="form-name" value="contact" />
              <SubmitContainer>
                <Submit progress={progress}>Submit</Submit>
              </SubmitContainer>
            </Form>
          </FormAndSubmitButton>
        </Container>
      </TitleAndForm>
    </ContactContainer>
  );
};

export default ContactForm;
