import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { size } from "../theme/theme";

const PaddedContainer = styled.div`
  position: relative;
  z-index: 9;
  padding: 0 ${({ theme }) => theme.spaces["xxs"]};
  ${(props) => css`
    ${props.style}
  `}
  @media ${size.m} {
    margin: 0 auto;
    width: 680px;
    padding: 0;
  }
  @media ${size.l} {
    width: 800px;
  }
  @media ${size.xl} {
    width: 990px;
  }
`;

const Pattern = styled.div`
  ${(props) => css`
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(${props.pattern});
  `}
`;

const PageContainer = styled.div`
  ${(props) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    ${props.style}
  `}
`;

export const Container = ({ page, style, children, pattern }) => {
  return page ? (
    <PageContainer withPattern={pattern} style={style}>
      {pattern && <Pattern pattern={pattern} />}
      <div style={pattern && { padding: "50px 0" }}>{children}</div>
    </PageContainer>
  ) : (
    <PaddedContainer style={style} pattern={pattern}>
      {children}
    </PaddedContainer>
  );
};
Container.defaultProps = {
  page: false,
  style: {},
  pattern: null,
};

Container.propTypes = {
  page: PropTypes.bool,
  style: PropTypes.object,
  pattern: PropTypes.any,
};

export default Container;
