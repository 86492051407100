import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div``;

const Text = styled.div`
  margin: 0;
  overflow: hidden;
`;

const Line = styled.span`
  ${(props) => css`
    display: block;
    transform: translate(0, 100%);
    transition: max-height 0.4s ease;
    max-height: 800px;
    ${props.animateIn
      ? css`
          animation: ${reveal()} 0.55s cubic-bezier(0.6, 0.01, 0.06, 1)
            ${props.delay}s;
          animation-fill-mode: forwards;
        `
      : css`
          animation: ${unreveal()} 0.4s cubic-bezier(0.6, 0.01, 0.06, 1);
          animation-fill-mode: forwards;
          max-height: 0px;
        `}

    ${props.animateOut &&
    css`
      animation: ${unreveal()} 0.4s cubic-bezier(0.6, 0.01, 0.06, 1);
      animation-fill-mode: forwards;
      max-height: 0px;
    `}
  `}
`;

const reveal = () => keyframes`
    0% {
      transform: translate(0, 150%) rotate(5deg);
    }
    100% {
      transform: translate(0, 0) rotate(0);
    }
`;

const unreveal = () => keyframes`
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 100%);
    }
`;

const TextReveal = ({ children, delay, rotate, animateIn, animateOut }) => {
  return (
    <Container>
      <Text>
        <Line
          animateIn={animateIn}
          animateOut={animateOut}
          delay={delay}
          rotate={rotate ? 1 : 0}
        >
          {children}
        </Line>
      </Text>
    </Container>
  );
};

TextReveal.defaultProps = {
  delay: 0,
  rotate: false,
  animateIn: true,
};

TextReveal.propTypes = {
  delay: PropTypes.number,
  rotate: PropTypes.bool,
  animateIn: PropTypes.bool,
};

export default TextReveal;
